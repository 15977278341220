import {Container, Typography} from "@mui/material";
import TagCloud from "../components/TagCloud";
import Posts from "../components/Posts";
import { useTranslation } from 'react-i18next';
import Page from "../components/Page";

const pageStyle = (theme) => ({
    [theme.breakpoints.down("sm")]: {
        marginTop: 10,
        paddingBottom: 5,
    },
    [theme.breakpoints.between("sm", "md")]: {
        marginTop: 15,
        paddingBottom: 10,
    },
    [theme.breakpoints.up("md")]: {
        marginTop: 20,
        paddingBottom: 20,
    },
});

const headlineStyle = (theme) => ({
    [theme.breakpoints.down("sm")]: {
        fontSize: "48px",
        marginTop: 2,
        marginBottom: 2,
    },
    [theme.breakpoints.between("sm", "md")]: {
        fontSize: "64px",
        marginTop: 8,
        marginBottom: 8,
    },
    [theme.breakpoints.up("md")]: {
        fontSize: "75px",
        marginTop: 10,
        marginBottom: 10,
    },
});

export default function Home() {
    const { t } = useTranslation();

    return (
        <div style={{width: "100%", backgroundColor: "white"}}>
            <Container sx={pageStyle}>
                <Typography
                    sx={headlineStyle}
                    variant="h1"
                    align="center"
                >
                    {t("hampus_dunstrom_consulting")}
                </Typography>
                <Page pageId={170} />
                <TagCloud min={2} />
                <Posts />
            </Container>
        </div>
    )
}

