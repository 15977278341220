import {useQuery} from "react-query";
import {getMedia, getPage} from "../api";
import i18n from "i18next";
import {Box, Card, CardContent, Typography} from "@mui/material";
import ContentSection from "./ContentSection";


export default function Page({pageId}) {
    const pageQuery = useQuery(
        ['page', pageId, i18n.language],
        () => {return getPage(pageId)}
    );
    const page = pageQuery.isLoading ? null : pageQuery.data[0];
    const mediaId = pageQuery.isLoading ? null : page.featured_media;
    const mediaQuery = useQuery({
        queryKey: ['media', mediaId],
        queryFn: () => {return getMedia([mediaId])},
    });
    const media = mediaQuery.isLoading ? null : mediaQuery.data[0];

    const content = page ? (
        <Card>
            <CardContent>
                <Typography variant="h2">{page.title.rendered}</Typography>
                <div
                    dangerouslySetInnerHTML={{__html: page.content.rendered}}
                />
            </CardContent>
        </Card>
    ): null;

    const image = media ? (
        <Box sx={{margin: 5}}>
            <img
                src={media.source_url}
                alt={media.alt_text}
                style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    verticalAlign: "middle",
                    borderRadius: "5px",
                }}
            />
        </Box>
    ) : null

    return <ContentSection content={content} image={image} sx={{marginBottom: 5}} />
}
