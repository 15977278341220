import {Grid, styled, Toolbar} from "@mui/material";
import i18n from "i18next";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";

const toolbarStyle = (theme) => ({
    paddingRight: "7% !important",
    paddingLeft: "7% !important",
    backgroundColor: theme.palette.primary.light,
    position: "fixed",
    top: 0,
    width: "100%",
    maxHeight: "100px",
    zIndex: 999,
});

const Menu = styled('div')(({theme}) => ({
    margin: "0",
    padding: "0",
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    "& a": {
        [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
            margin: "0 10px",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "28px",
            margin: "0 15px",
        },
        [theme.breakpoints.up("md")]: {
            fontSize: "35px",
            margin: "0 20px",
        },
        "&:hover": {
            color: theme.palette.secondary.main,
            textDecoration: "none",
        }
    }
}));

const Logo = styled('a')(({theme}) => ({
    display: "block",
    "& img": {
        height: "80%",
        marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
        height: "50px",
        "& img": {
            marginTop: "5px",
        }
    },
    [theme.breakpoints.between("sm", "md")]: {
        height: "75px",
    },
    [theme.breakpoints.up("md")]: {
        height: "100px",
    },
}));

const toggleLanguage = (SetURLSearchParams, URLSearchParams) => {
    i18n.language === "en" ? i18n.changeLanguage("sv") : i18n.changeLanguage("en");
    URLSearchParams.set("lang", i18n.language);
    SetURLSearchParams(URLSearchParams);
}

export default function Header() {
    const { t } = useTranslation();
    const [URLSearchParams, SetURLSearchParams] = useSearchParams();

    return (
        <Toolbar sx={toolbarStyle}>
            <Grid container sx={{maxHeight: "100px"}}>
                <Grid item xs={2} sx={{maxHeight: "100px"}}>
                    <Logo href="/" onClick={() => {window.scrollTo(0,0)}}>
                        <img
                            src="/images/hdc_logo_v4.png"
                            alt="logotype"
                        />
                    </Logo>
                </Grid>
                <Grid item xs={8}>
                    <Menu sx={{textTransform: "capitalize"}}>
                        <a href={`/?lang=${i18n.language}`} onClick={() => {window.scrollTo(0,0)}}>{t("merits")}</a>
                        <a href={"#contact-anchor"}>{t("contact")}</a>
                    </Menu>
                </Grid>
                <Grid item xs={2}>
                    <div
                        style={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <span
                            style={{textTransform: "capitalize"}}
                            className={"link"}
                            onClick={() => toggleLanguage(SetURLSearchParams, URLSearchParams)}
                        >
                            {t("in_english/swedish")}
                        </span>
                    </div>
                </Grid>
            </Grid>
        </Toolbar>
    )
}