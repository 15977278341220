import { createTheme } from '@mui/material/styles';


const theme = createTheme({
    palette: {
        primary: {
            main: '#00737a',
            light: '#8cbfc3'
        },
        secondary: {
            main: '#ffcc00',
            light: 'ffe8a6'
        }
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (themeParam) => `
            a, a.MuiLink-root, .link {
                color: ${themeParam.palette.primary.main};
                text-decoration: none;
            }
            a:hover, .link:hover {
                cursor: pointer;
                text-decoration: underline;
            }
            `,
        }

    },
})

const headlineStyles = {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontSize: '2rem',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.8rem'
    }
}

theme.typography.h1 = {
    ...headlineStyles,
    fontSize: '3rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '2.6rem'
    },
}

theme.typography.h2 = {
    ...headlineStyles,
    fontSize: '2.5rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '2.2rem'
    }
}

theme.typography.h3 = {
    ...headlineStyles,
    fontSize: '2.2rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '2rem'
    }
}

theme.typography.h4 = {
    ...headlineStyles
}

theme.typography.h5 = {
    ...headlineStyles,
}

theme.typography.h6 = {
    ...headlineStyles,
}

export default theme