import {Outlet, useSearchParams} from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {Paper} from "@mui/material";
import i18n from "i18next";


export default function Layout() {
    const [URLSearchParams] = useSearchParams();
    let lang = URLSearchParams.get("lang");
    if (lang) {
        localStorage.setItem("lang", lang);
    } else {
        lang = localStorage.getItem("lang")
    }
    if (lang) {
        i18n.changeLanguage(lang);
    }

    return (
        <Paper elevation={0} sx={{backgroundColor: "white"}}>
            <Header/>
            <div style={{minHeight: "1000px"}}>
                <Outlet/>
            </div>
            <Footer />
        </Paper>
    )
}