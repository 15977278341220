import {Grid, Paper, styled} from "@mui/material";
import { useSearchParams } from "react-router-dom"
import {useQuery} from "react-query";
import {getTags} from "../api";
import i18n from "i18next";

const getTagComponent = (fontSize, horizontalPadding, isSelected) => styled(Paper)(({theme}) => ({
    "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: isSelected ? theme.palette.primary.main : "white",
    },
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: horizontalPadding,
    paddingRight: horizontalPadding,
    fontSize: fontSize,
    cursor: "pointer",
    backgroundColor: isSelected ? theme.palette.secondary.main : theme.palette.primary.light,
    color: isSelected ? "white" : "black",
    minWidth: "40px",
    textAlign: "center",
}));


export default function TagCloud({size, include, sx, min= 1}) {

    const [URLSearchParams, SetURLSearchParams] = useSearchParams();
    const selectedTag = URLSearchParams.get("tag");
    const tagsQuery = useQuery(['tags', i18n.language], getTags);
    const tags = tagsQuery.isLoading ? [] : tagsQuery.data;

    const gotoTag = (tagId) => {
        if (tagId === Number(selectedTag)) {
            URLSearchParams.delete("tag")
            SetURLSearchParams(URLSearchParams);
        } else {
            URLSearchParams.set("tag", tagId);
            SetURLSearchParams(URLSearchParams);
        }
    }

    const items = tags.map(tag => {
        const fontSize = size ? size : 12+5*tag.count;
        const horizontalPadding = size ? size * 0.5 : 5+2.5*tag.count;
        const Tag = getTagComponent(
            fontSize,
            horizontalPadding,
            Number(selectedTag) === tag.id
        );
        if (include && !include.includes(tag.id)) {
            return null;
        }
        if (tag.count >= min) {
            return (
                <Grid item key={tag.id}>
                    <Tag onClick={() => {gotoTag(tag.id)}}>
                        {tag.name}
                    </Tag>
                </Grid>
            );
        }
        return null;
    });

    return (
        <Grid sx={sx} container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            {items}
        </Grid>
    )
}