import i18n from "i18next";
const BASE_URL = "https://wp.consulting.dunstrom.com/wp-json/wp/v2"

export function getTags() {
    return fetch(`${BASE_URL}/tags?per_page=100&lang=${i18n.language}`).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            console.error("Failed to get tags", response)
            throw new Error("Failed to get tags");
        }
    });
}

export function getPosts(params) {
    let url = `${BASE_URL}/posts?per_page=100`;
    if (typeof params === "object") {
        Object.keys(params).forEach((key) => {
            url += `&${key}=${params[key]}`;
        });
    }
    return fetch(url).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            console.error("Failed to get posts", response)
            throw new Error("Failed to get posts");
        }
    });
}

export function getMedia(mediaIds) {
    return fetch(`${BASE_URL}/media?include=${mediaIds.join(',')}`).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            console.error("Failed to get media", response)
            throw new Error("Failed to get media");
        }
    })
}

export function getPage(pageId) {
    return fetch(`${BASE_URL}/pages?id=${pageId}&lang=${i18n.language}`).then((response) => {
        if (response.ok) {
            return response.json();
        } else {
            console.error("Failed to get page", response)
            throw new Error("Failed to get page");
        }
    })
}
