import {Card, CardContent, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";


export default function Footer() {
    const { t } = useTranslation();

    return (
        <footer style={{
            backgroundImage: `url(/images/element1.png)`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "100px 0 300px 0",
        }}>
            <Card sx={{width: "350px", padding: "20px"}}>
                <CardContent>
                    <Typography id="contact-anchor" variant="h4" sx={{marginBottom: "25px", textTransform: "capitalize"}}>
                        {t("contact")}
                    </Typography>
                    <Typography variant="h6">{t("hampus_dunstrom")}</Typography>
                    <a href="mailto:info@hampusdunstrom.se">
                        <Typography variant="body1">info@hampusdunstrom.se</Typography>
                    </a>
                    <Typography variant="body1">Portgatan 3</Typography>
                    <Typography variant="body1">633 62 Eskilstuna</Typography>
                </CardContent>
            </Card>
        </footer>
    )
}