import {format, parse} from "date-fns";
import sv from "date-fns/locale/sv/index.js";
import {Box, Card, CardContent, Grid, Link, Typography, useMediaQuery} from "@mui/material";
import TagCloud from "./TagCloud";
import {useState} from "react";
import ContentSection from "./ContentSection";

const DATE_FORMAT = "MMMM y"
const TAG_CLOUD_FONT_SIZE = 17
const HIDE_TEXT = "Dölj"
const READ_MORE_TEXT = "Läs mer"
const MORE_COMMENT = "<!--more-->"

function parseDate(dateString) {
    return parse(`${dateString.slice(0,4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`, "y-MM-dd", new Date());
}

export default function Post({post, idx, media}) {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const [showDetails, setShowDetails] = useState(false)
    const startDate = format(parseDate(post.acf.start_date), DATE_FORMAT, {locale: sv});
    const endDate = post.acf.end_date ? format(parseDate(post.acf.end_date), DATE_FORMAT, {locale: sv}): undefined;
    const [introduction, details]  = post.content.rendered.split(MORE_COMMENT)
    const content = showDetails ? introduction + details : introduction;
    const readMore = showDetails ? HIDE_TEXT : READ_MORE_TEXT

    const card = (
        <Card key={post.id} sx={{margin: isMobile ? 1 : 5}}>
            <CardContent>
                <Typography variant="h4">{post.title.rendered}</Typography>
                <Typography variant="body1">{startDate} - {endDate}</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Box
                            sx={{marginTop: 2}}
                            dangerouslySetInnerHTML={{__html: content }}
                        />
                        <Link onClick={() => setShowDetails(!showDetails)}>
                            <Typography variant={"body1"}>{ readMore }</Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <TagCloud size={TAG_CLOUD_FONT_SIZE} include={post.tags} min={1} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

    const image = media ? (
        <Box sx={{margin: 5}}>
            <img
                src={media.source_url}
                alt={media.alt_text}
                style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    verticalAlign: "middle",
                    borderRadius: "5px",
                }}
            />
        </Box>
    ) : null;

    return <ContentSection content={card} image={image} imageFirst={isMobile || idx % 2} />
}