import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translations from "./resources/translations.json";


console.log("I18n init before", translations);
i18n
    .use(initReactI18next)
    .init({
        resources: translations,
        lng: "sv",
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });
console.log("I18n init after");

export default i18n;