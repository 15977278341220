import {useQuery} from "react-query";
import {getMedia, getPosts} from "../api";
import {Box} from "@mui/material";
import {differenceInMilliseconds, parse} from "date-fns";
import {useSearchParams} from "react-router-dom";
import Post from "./Post";
import i18n from "i18next";


function parseDate(dateString) {
    return parse(`${dateString.slice(0,4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}`, "y-MM-dd", new Date());
}

export default function Posts() {

    const [URLSearchParams] = useSearchParams();
    const selectedTag = URLSearchParams.get("tag");
    const params = selectedTag ? {tags: selectedTag} : {};
    params["lang"] = i18n.language;
    const postsQuery = useQuery(["posts", selectedTag, i18n.language], () => getPosts(params));
    const mediaIds = postsQuery.data
        ?.filter(post => {return post.featured_media !== 0})
        ?.map(post => {return post.featured_media})
    const mediaQuery = useQuery({
            queryKey: ["media", mediaIds],
            queryFn: () => getMedia(mediaIds),
            enabled: !!mediaIds
        });
    const media = {}
    mediaQuery.data?.forEach((m) => {
        media[m.id] = m
    })
    let postCards = [];
    if (postsQuery.isSuccess) {
        const posts = postsQuery.data;

        // Sort on start date, the newest first
        posts.sort((a, b) => {
            return differenceInMilliseconds(parseDate(b.acf.start_date), parseDate(a.acf.start_date));
        })

        // Create cards for each post
        postCards = postsQuery.isLoading ? undefined : posts.map((post, idx) => {
            return (<Post post={post} idx={idx} key={idx} media={media[post.featured_media]}></Post>
            );
        });
    }

    return (
        <Box sx={{marginTop: 5}}>
            {postCards}
        </Box>
    )

}