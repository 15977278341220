import {Grid} from "@mui/material";

export default function ContentSection({content, image, sx, imageFirst= true}) {
    const gridItemStyle = {display: "flex", justifyContent: "center", alignItems: "center"}
    const imageContainerStyle = imageFirst ? gridItemStyle : {order: 2, ...gridItemStyle}
    return (
        <Grid container spacing={2} sx={sx}>
            <Grid item xs={12} md={6} sx={imageContainerStyle}>
                { image }
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
                { content }
            </Grid>
        </Grid>
    );
}